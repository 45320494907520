<template>
  <!-- 教练团队 -->
  <div class="jy_trip">
    <div class="jy_trip_bg" v-if="Object.keys(tripData).length != 0">
      <img :src="tripData.xc_bg ? tripData.xc_bg : region_bg" alt="" style="width:100%" />
    </div>
    <div
      class="jy_trip_nav"
      v-if="Object.keys(tripData).length != 0"
      v-html="tripData.xc_html"
    ></div>
    <div class="details_empty" v-else>
      <img
        src="@/assets/image/xwdt/xwdt-data.webp"
        alt=""
        style="margin:0 auto"
      />
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      region_bg: require('@/assets/image/all/jy_bg.png'),
      tripData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.tripData = res.data.data.行程
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.jy_trip {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .jy_trip_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
    img {
      animation: scaleAnim 3s ease forwards;
    }
  }
  .jy_trip_nav {
    margin: 1rem auto;
    width: 15rem;
    /deep/img{
        max-width: 100%;
        display: block;
        margin: 0 auto !important;
    }
  }
  .details_empty {
    width: 15rem;
    display: flex;
    flex-wrap: wrap;
    margin: 1rem auto;
    position: relative;
  }
  .details_empty {
    width: 15rem;
    background-color: #fff;
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
